import React from 'react'
import availableLangs from '@localization/availableLangs'
import SeoContainer from '@containers/SeoContainer'
import LandingLayout from '@layouts/LandingLayout'
import { TextPageContainer, Paragraph, PageTitle } from '@common/typography'

const ImpressumPage = () => {
  return (
    <>
      <SeoContainer pageLang={availableLangs.en} pagePath='impressum' />
      <LandingLayout>
        <TextPageContainer>
          <PageTitle>Imprint</PageTitle>
          <Paragraph>
            Information in accordance with §5 of the E-Commerce Act, §14 of the
            Unternehmensgesetzbuch, §63 of the Commercial Code and disclosure requirements
            under §25 of the Media Act.
          </Paragraph>
          <Paragraph>
            weCreate Data GmbH
            <br />
            Obere Augartenstrasse 2/24,
            <br />
            1020 Wien,
            <br />
            Austria
          </Paragraph>
          <Paragraph>
            Object of the company: Marketing
            <br />
            VAT-Number: ATU73053839
            <br />
            Corporate register number: 482162m
            <br />
            Corporate register court: Wien
            <br />
            Company location: Wien
          </Paragraph>
          <Paragraph>
            Phone: 06603819240
            <br />
            Email: johannes@wecreate.media
          </Paragraph>
          <Paragraph>
            Member of: WKO
            <br />
            Laws re. professions: Gewerbeordnung: www.ris.bka.gv.at
          </Paragraph>
          <Paragraph>
            Supervisory/Trade authority: Magistrat der Stadt Wien
            <br />
            Job title: Marketing
            <br />
            Awarding country: Österreich
          </Paragraph>
          <Paragraph>
            Chief executive
            <br />
            Johannes Ruisinger, Adil Sbai
          </Paragraph>
          <Paragraph>
            Ownership structure
            <br />
            Gesellschafter: Herr Sbai Adil 67,33%, Reafina AG 32,67%
          </Paragraph>
          <Paragraph>
            Source: Created with the Impressum Generator by AdSimple® Linkbuilding in
            cooperation with schoenheitsmagazin.at
            <br />
          </Paragraph>
        </TextPageContainer>
      </LandingLayout>
    </>
  )
}

export default ImpressumPage
